
import { defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";

const VUnitConfirmDeletion = defineComponent({
  name: "VUnitConfirmDeletion",
  components: { CModal },
  setup() {
    useHead({ title: "Confirmar exclusão do parceiro | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const _id = Number(route.params._id);
    const loading = reactive({ confirm: false });

    function handleClose() {
      router.back();
    }

    async function deleteUnit() {
      loading.confirm = true;
      const response = await store.dispatch("deleteUnit", { _id });
      loading.confirm = false;

      if (response?.status === 200) {
        store.dispatch("getUnits");
        handleClose();
      }
    }

    return { loading, deleteUnit, handleClose };
  },
});

export default VUnitConfirmDeletion;
